import _ from "lodash";
import dayjs from 'dayjs';
import { useReactToPrint } from 'react-to-print';
import { add } from 'mathjs';
import React, { useRef } from "react";
import Barcode from "react-barcode";

        // import QRCode from 'qrcode'

        // // With promises
        // QRCode.toDataURL('I am a pony!')
        // .then(url => {
        //     console.log(url)
        // })
        // .catch(err => {
        //     console.error(err)
        // })

        // // With async/await
        // const generateQR = async text => {
        // try {
        //     console.log(await QRCode.toDataURL(text))
        // } catch (err) {
        //     console.error(err)
        // }
        // }


// import ReactToPrint from "react-to-print";

// export function PrintingComponent({ children }) {
//     console.log({children})
//     // const auth = useAuthUser();
//     const linkToPrint = () => {
//         return (
//             <button>Click To PrintOF Body</button>
//         )
//     }
//     const componentRef = useRef();
//     return (
//         <>
//             <ReactToPrint trigger={linkToPrint} content={() => componentRef.current} />
//             <div ref={componentRef}>
//                 {children}
//             </div>
//         </>
//     );
// }


export class ProductionTag extends React.PureComponent {
  render() {

    // @media print {
    //     .pagebreak {
    //     page-break-before: always;
    //     }
    // }

    // let pageStyle = `
    // @page {
    //     size: 80mm 50mm;
    // }

    // @media all {
    //     .pagebreak {
    //     display: none;
    //     }
    // }
    // @media print {
    // @page { size: landscape; }
    // }
    // `;
    console.log("props: ", this.props)

    let quantity = '';
    let Operators = [];
    let ProductionRecords = this.props.records;
    ProductionRecords = ProductionRecords.sort(function(a,b){
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.date_created) - new Date(a.date_created);
    });
    // Calculate the quantity based on what action is sent through props
    if ( this.props.action === 'print_tag' ) {
        quantity = ProductionRecords[0].good_parts_produced;
        let Shift = ProductionRecords[0].shift_ID !== '' && ProductionRecords[0].shift_ID !== null && parseInt(ProductionRecords[0].shift_ID) > 0 ? this.props.Shifts.filter((s) => parseInt(s.shift_ID) === parseInt(ProductionRecords[0].shift_ID))[0] : [];
        Operators = [ { first_name: ProductionRecords[0].first_name, last_name: ProductionRecords[0].last_name, Shift: Shift } ];
    }
    else if ( this.props.action === 'print_tag_all_quantities' || this.props.action === 'print_tag_selected_quantities' ) {
        // Loop through props.records and get quantity
        ProductionRecords.forEach((r, index) => {
            quantity = add(quantity, parseInt(r.good_parts_produced)).toFixed(0);
        });
    }

    if ( this.props.tag === 'ProductionTag' ) {
        return (
            // , width: 384+'px', height: 576+'px'
            <div className="mt-5 ms-5" style={{ marginTop: 50+'px', marginLeft: 50+'px', marginRight: 50+'px'}}>
                <style type="text/css" media="print">
                    {" @page { size: landscape; } "}
                </style>                

                <div className="row text-dark">
                    {/* SHIPPING ONLY - ROBOT INSPECTION DETAILS */}
                    {
                        (this.props.machine_name === 'Shipping' && this.props.records[0].robot && this.props.records[0].robot !== '' && 
                            (parseInt(this.props.records[0].part_ID) === 2 || parseInt(this.props.records[0].part_ID) === 3 || parseInt(this.props.records[0].part_ID) === 1 || parseInt(this.props.records[0].part_ID) === 36 || parseInt(this.props.records[0].part_ID) === 4 ) &&
                        <>
                            <div className="col-4 border-bottom border-dark">
                                <div className="">PART NO (P)</div>
                                <div className="h1">{this.props.part_number}</div>
                                <div className="mb-1"><Barcode value={this.props.part_number} displayValue={false} height={50} width={2} /></div>
                            </div>
                            <div className="col-8 border-bottom border-dark">
                                <div className="">INSPECTION</div>
                                <div className="h1">{this.props.records[0].robot}</div>
                                <div className="h4">{this.props.records[0].robot_date_inspected}</div>
                            </div>
                            {/* <div className="col-4 border-bottom border-dark">
                                <div className="">LABEL CREATED</div>
                                <div className="h4">{this.props.records[0].robot_date_created}</div>
                            </div> */}
                        </>) ||
                        <div className="col-12 border-bottom border-dark">
                            <div className="">PART NO (P)</div>
                            <div className="h1">{this.props.part_number}</div>
                            <div className="mb-1"><Barcode value={this.props.part_number} displayValue={false} height={50} width={2} /></div>
                        </div>

                    }
                    <div className="col-5 border border-dark border-start-0">
                        <div className="">QUANTITY (Q)</div>
                        <div className="h1">{quantity}</div>
                        <div className="mb-1"><Barcode value={`${quantity}`} displayValue={false} height={50} width={2} /></div>
                    </div>
                    <div className="col-7 border border-dark border-end-0">
                        <div className="">P.O. NO (K)</div>
                        <div className=""></div>
                    </div>
                    <div className="col-7 border border-dark border-start-0">
                        <div className="">SUPPLIER (V)</div>
                        {
                            (this.props.machine_name === 'Shipping' && (parseInt(this.props.records[0].part_ID) === 38 || parseInt(this.props.records[0].part_ID) === 37 || parseInt(this.props.records[0].part_ID) === 2 || parseInt(this.props.records[0].part_ID) === 3 || parseInt(this.props.records[0].part_ID) === 1 || parseInt(this.props.records[0].part_ID) === 36 || parseInt(this.props.records[0].part_ID) === 4 ) &&
                            <>
                                <div className="h1">PANTHER METALFLOW</div>
                                <div className="mb-1"><Barcode value="PANTHER METALFLOW" displayValue={false} height={50} width={2} /></div>
                            </>) ||
                            <>
                                <div className="h1">TURNING POINT</div>
                                <div className="mb-1"><Barcode value="TURNING POINT" displayValue={false} height={50} width={2} /></div>
                            </>
                        }
                    </div>
                    <div className="col-5 border border-dark border-end-0">
                        <div className="row border-bottom border-dark">
                            <div className="col-12 h2">{this.props.part_description}</div>
                        </div>
                        <div className="row m-0 p-0" style={{height: 50+'%'}}>
                            <div className="col-12 m-0 p-0 border-dark">
                                <div className="h3">REVISION</div>
                                <div className="h1">{this.props.part_revision}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-7 border border-dark border-start-0 border-bottom-0">
                        <div className="">SERIAL (S)</div>
                        <div className="h1">W{ProductionRecords[0].serial_number}</div>
                        <div className=""><Barcode value={`${ProductionRecords[0].serial_number}`} displayValue={false} height={50} width={2} /></div>
                    </div>
                    <div className="col-5 border border-dark border-end-0 border-bottom-0">
                        <div className="row m-0 p-0">
                            <div className="col-9 m-0 p-0">
                                <div className="h3">DEVIATION</div>
                                <div className="h1">{this.props.part_deviation}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if ( this.props.tag === 'ProductionTagOperator') {
        return (
            // , width: 384+'px', height: 576+'px'
            <div className="mt-5 ms-5" style={{ marginTop: 50+'px', marginLeft: 50+'px', marginRight: 50+'px'}}>
                <style type="text/css" media="print">
                    {" @page { size: landscape; } "}

                </style>                

                <div className="row text-dark">
                    <div className="col border-end border-dark m-0 p-0">
                        <div className="col-12 m-0 px-2">
                            <div className="fw-bold" style={{fontSize: 50+'px'}}>{this.props.machine_name}</div>
                        </div>
                        <div className="col-12 m-0 px-2">
                            <div className="fw-bold" style={{fontSize: 50+'px'}}>{this.props.part_number}</div>
                            <div className="h3 m-0 p-0">
                                {this.props.part_description}
                            </div>
                        </div>
                        <div className="col-12 h3 border-top border-dark m-0 p-2">
                            <div className="">QUANTITY (Q)</div>
                            <div className="fw-bold" style={{fontSize: 50+'px'}}>{quantity}</div>
                        </div>
                        <div className="col-12 border-top border-dark border-bottom-0 m-0 p-2">
                            <div className="fw-bold" style={{fontSize: 50+'px'}}>W{ProductionRecords[0].serial_number}</div>
                            <div className=""><Barcode value={`${ProductionRecords[0].serial_number}`} displayValue={false} height={50} width={2} /></div>
                        </div>
                    </div>
                    <div className="col m-0 p-0">
                        {ProductionRecords.map((r, index) => {
                            // Limit to the 6 most recent records, we can only get 6 on a tag right now. TO DO: Group operators' records by their shift
                            if ( index < 6 ) {
                                let Shift = ProductionRecords[0].shift_ID !== '' && ProductionRecords[0].shift_ID !== null && parseInt(ProductionRecords[0].shift_ID) > 0 ? this.props.Shifts.filter((s) => parseInt(s.shift_ID) === parseInt(ProductionRecords[0].shift_ID))[0] : [];
                                return (
                                    <div key={r.production_record_ID} className={`${index > 0 && "border-dark border-top"} m-0 p-2 fw-bolder row`}>
                                        <div className="col-7 m-0 p-0">
                                            <div className="fw-bold h5">
                                                {r.first_name} {r.last_name.charAt(0)}. - Shift {Shift.shift_group} 
                                            </div>
                                            <div className="">
                                                <span className="text-muted fw-normal">Quantity:</span> <span className="h5">{r.good_parts_produced}</span>
                                                <span className="text-muted fw-normal float-end m-0 p-0 me-2">Recorded:</span>
                                            </div>
                                        </div>
                                        <div className="col-5 m-0 p-0">
                                            <div className="h5 clearfix m-0 p-0">
                                                <span className="float-start">{dayjs(r.date_scheduled).format('M/D/YY')}</span>
                                                {Shift.shift_name && Shift.shift_name !== "" && <span className="h5 float-end">{Shift.shift_name}</span>}
                                            </div>
                                            <div className="clearfix m-0 p-0">
                                                <span className="h5 float-start">{dayjs(r.date_created).format('M/D/YY')}</span>
                                                <span className="h5 float-end">{dayjs(r.date_created).format('h:mm a')}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else {
                                return false;
                            }
                        })}
                        {
                            parseInt(this.props.machine_ID) < 14 &&
                            <>
                                <div className="border-dark border-top m-0 p-2 fw-bolder">
                                    <div className="h4 m-0">
                                        INSPECTION COMPLETE FORM BELOW
                                    </div>
                                </div>
                                <div className="border-dark border-top m-0 p-2 fw-bolder py-4">
                                    <div className="row m-0 p-0">
                                        <div className="col-3">Shelf</div>
                                        <div className="col-3">Clean</div>
                                        <div className="col-3">Load</div>
                                        <div className="col-3">Inspected</div>
                                    </div>
                                </div>
                                <div className="border-dark border-top m-0 p-2 fw-bolder h-25">
                                    <div className="row px-2 text-center h-100 fw-normal h5">
                                        <div className="col-6 border-end border-dark h-100">
                                            Visual Tray Part Count
                                        </div>
                                        <div className="col-6 h-100">
                                            Inspector / Date
                                        </div>
                                    </div>
                                </div>
                                <div className="border-dark border-top m-0 p-2 fw-bolder h-25">
                                    <div className="row px-2 text-center h-100 fw-normal h5">
                                        <div className="col-6 border-end border-dark h-100">
                                            Good Part Count
                                        </div>
                                        <div className="col-6 h-100">
                                            Bad Part Count
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        );
    }
  }
}

// export function PrintingComponent() {
// const componentRef = useRef();
//   const handlePrint = useReactToPrint({
//     content: () => componentRef.current,
//   });

//   return (
//     <div>
//       <div
//       style={{ display: "none" }}// This make ProductionTag show   only while printing
//       > 
//        <ProductionTag ref={componentRef} />
//       </div>
//       <button onClick={handlePrint}>Print this out!</button>
//     </div>
//   );
// };
//  default PrintingComponent;

